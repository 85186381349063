<template>
    <b-card class="white-bg">
        <ValidationObserver ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(getReport)" id="user-form">
                <b-row>
                    <b-col cols="12" sm="6" md="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-form-group label="Reporte" invalid-feedback="Selecciona el reporte" :state="errors[0] ? false : null">
                                <v-select :options="reports" v-model="report" :clearable="true" placeholder="Reporte"
                                          :reduce="report => report.value">
                                    <template #no-options>Sin resultados.</template>
                                </v-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="6" md="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-form-group label="Fecha inicial" invalid-feedback="Ingresa la fecha inicial" :state="errors[0] ? false : null">
                                <b-form-datepicker v-model="startDate" boundary="window" placeholder="Fecha inicial"
                                                   v-bind="datePickerLabels" locale="es" hide-header></b-form-datepicker>
                                </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="6" md="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-form-group label="Fecha final" invalid-feedback="Ingresa la fecha final" :state="errors[0] ? false : null">
                                <b-form-datepicker v-model="endDate" boundary="window" placeholder="Fecha final"
                                                   v-bind="datePickerLabels" locale="es" hide-header></b-form-datepicker>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver>
        <template #footer>
            <div class="text-right">
                <b-button @click="validateForm(false)" variant="primary"><font-awesome-icon icon="print"></font-awesome-icon> Generar reporte</b-button>
            </div>
        </template>
    </b-card>
</template>

<script>
import { reportUrl } from '@routes';
import * as constants from '@constants';
import { generateReport } from '../../js/generateReport';
import { datePickerLabels } from '../../i18n/datePickerI18n';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

export default {
    name: 'ReportIndex',
    data() {
        return {
            report: null,
            startDate: null,
            endDate: null,
            reports: [
                { label: 'Reservaciones', value: constants.REPORTS.BUSINESS_ORDER },
                { label: 'Órdenes de servicio', value: constants.REPORTS.SERVICE_ORDER },
                { label: 'Cuentas por cobrar', value: constants.REPORTS.RECEIVABLE_ACCOUNTS },
                { label: 'Cuentas por pagar', value: constants.REPORTS.PAYABLE_ACCOUNTS }
            ],
            datePickerLabels: datePickerLabels.es,
            breadcrumb: {
                title: 'Reportes',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        validateForm() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    this.getReport();
                }
            });
        },
        getReport() {
            this.isLoading = true;

            this.axios.get(reportUrl(), {
                params: {
                    report: this.report,
                    startDate: this.startDate,
                    endDate: this.endDate
                }
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    generateReport(this.report, response.data.data, {
                        reportName: response.data.reportName
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>