// import 'core-js/fn/number/is-nan'; // Fix para exceljs en modo prod
import * as Excel from 'exceljs/dist/exceljs';
import saveAs from 'file-saver';
import reportsData from '../js/reportData';

export function generateReport(report, rows, params) {
    return new Promise(resolve => {
        let worksheet, rowIndex;
        let columns = [];
        let width = 14;
        let style = { font: { name: 'Arial' }};
        let workbook = new Excel.Workbook();
        let headers = reportsData[report].headers;
        let data = reportsData[report].formatData(rows);
        let worksheetName = params.reportName;

        workbook.creator = 'Tritonix';
        workbook.lastModifiedBy = 'Tritonix';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();

        worksheet = workbook.addWorksheet(worksheetName);

        for (let header of headers) {
            columns.push({ key: header.key, width: header.width, style: style });
        }

        for (let i = columns.length; i < 12; i++) {
            columns.push({ key: i, width, style });
        }

        worksheet.properties.defaultRowHeight = 15;
        worksheet.columns = columns;

        // Fila 1: Encabezados
        rowIndex = 1;
        worksheet.addRow(headers.map(header => header.key));
        worksheet.getRow(rowIndex).font = { name: 'Arial', bold: true };
        worksheet.getRow(rowIndex).alignment = { wrapText: true };
        worksheet.getRow(rowIndex).height = 30;

        for (let i = 1; i < headers.length; i++) {
            if (headers[i - 1].key === headers[i].key) {
                worksheet.mergeCells(rowIndex, i, rowIndex, i + 1);
            }
        }

        // Filas 2 - n: Datos
        for (let item of data) {
            rowIndex++;

            if (Array.isArray(item)) {
                worksheet.addRow(item);

                for (let i = 1; i <= headers.length; i++) {
                    let cell = worksheet.getRow(rowIndex).getCell(i);

                    if ('format' in headers[i-1]) {
                        cell.numFmt = headers[i-1].format;
                    }

                    if ('alignment' in headers[i-1]) {
                        cell.alignment = headers[i-1].alignment;
                    } else {
                        cell.alignment = {};
                    }

                    cell.alignment.wrapText = true;
                }
            } else {
                worksheet.addRow(item.data);

                for (let i = 1; i <= headers.length; i++) {
                    let cell = worksheet.getRow(rowIndex).getCell(i);

                    if ('format' in headers[i-1]) {
                        cell.numFmt = headers[i-1].format;
                    }

                    if ('alignment' in headers[i-1]) {
                        cell.alignment = headers[i-1].alignment;
                    } else {
                        cell.alignment = {};
                    }

                    cell.alignment.wrapText = true;
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: `FF${item.color}` }
                    };
                }
            }
        }

        workbook.eachSheet(sheet => {
            sheet.state = 'visible';
        });

        workbook.xlsx.writeBuffer({ base64: true }).then(buffer => {
            let data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            saveAs(data, `${params.reportName}.xlsx`);
            resolve();
        }).catch(error => console.error(error));
    });
}