export let datePickerLabels = {
    es: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Año anterior',
        labelPrevMonth: 'Mes anterior',
        labelCurrentMonth: 'Mes actual',
        labelNextMonth: 'Siguiente mes',
        labelNextYear: 'Siguiente año',
        labelNextDecade: 'Siguiente década',
        labelToday: 'Hoy',
        labelSelected: 'Seleccionada',
        labelNoDateSelected: 'Sin selección',
        labelCalendar: 'Calendario',
        labelNav: 'Navegación',
        labelHelp: 'Usa las flechas para navegar en el calendario',
        dateFormatOptions: { year: 'numeric', month: '2-digit', day: '2-digit' }
    }
};