import { REPORTS } from './constants';

let reportsData = {};

reportsData[REPORTS.BUSINESS_ORDER] = {
    headers: [
        { key: 'Fecha', width: 20 },
        { key: 'Cliente (agencia)', width: 30 },
        { key: 'Clave de confirmación', width: 20 },
        { key: 'Reserva, folio o nota', width: 20 },
        { key: 'PAX', width: 60 },
        { key: 'Tour', width: 40 },
        { key: 'Hotel', width: 20 },
        { key: 'Vuelo', width: 20 },
        { key: 'Cant. adultos', width: 15 },
        { key: 'Cant. niños', width: 15 },
        { key: 'Precio adultos', width: 20, format: '$#,##0.00' },
        { key: 'Precio niños', width: 20, format: '$#,##0.00' },
        { key: 'Total PAX', width: 12 },
        { key: 'Total', width: 20, format: '$#,##0.00' }
    ],
    formatData(rows) {
        let data = [];

        for (let row of rows) {
            data.push([
                row.processed,
                row.commercialName,
                row.folio,
                row.customerFolio,
                row.passengers,
                row.tour,
                row.hotel,
                row.flight,
                parseInt(row.adultsQty),
                parseInt(row.childrenQty),
                row.adultFare,
                row.childFare,
                parseInt(row.passengersQty),
                parseFloat(row.total)
            ]);
        }

        return data;
    }
};

reportsData[REPORTS.SERVICE_ORDER] = {
    headers: [
        { key: 'Fecha', width: 20 },
        { key: 'Cliente (agencia)', width: 30 },
        { key: 'Orden de servicio', width: 20 },
        { key: 'Clave de confirmación', width: 20 },
        { key: 'Reserva, folio o nota', width: 20 },
        { key: 'PAX', width: 60 },
        { key: 'Tour', width: 40 },
        { key: 'Hotel', width: 20 },
        { key: 'Vuelo', width: 20 },
        { key: 'Cant. adultos', width: 15 },
        { key: 'Cant. niños', width: 15 },
        { key: 'Precio adultos', width: 20, format: '$#,##0.00' },
        { key: 'Precio niños', width: 20, format: '$#,##0.00' },
        { key: 'Total PAX', width: 12 },
        { key: 'Total', width: 20, format: '$#,##0.00' }
    ],
    formatData(rows) {
        let data = [];

        for (let row of rows) {
            data.push([
                row.processed,
                row.commercialName,
                row.folio,
                row.businessOrderFolio,
                row.customerFolio,
                row.passengers,
                row.tour,
                row.hotel,
                row.flight,
                parseInt(row.adultsQty),
                parseInt(row.childrenQty),
                row.adultFare,
                row.childFare,
                parseInt(row.passengersQty),
                parseFloat(row.total)
            ]);
        }

        return data;
    }
};

reportsData[REPORTS.RECEIVABLE_ACCOUNTS] = {
    headers: [
        { key: 'Fecha', width: 20 },
        { key: 'Cliente (agencia)', width: 30 },
        { key: 'Clave de confirmación', width: 20 },
        { key: 'Reserva, folio o nota', width: 20 },
        { key: 'PAX', width: 60 },
        { key: 'Total PAX', width: 12 },
        { key: 'Estatus', width: 20 },
        { key: 'Total', width: 20, format: '$#,##0.00' },
        { key: 'Pagado', width: 20, format: '$#,##0.00' },
        { key: 'Saldo', width: 20, format: '$#,##0.00' }
    ],
    formatData(rows) {
        let data = [];

        for (let row of rows) {
            data.push([
                row.processed,
                row.commercialName,
                row.folio,
                row.customerFolio,
                row.passengers,
                row.passengersQty,
                row.status,
                parseFloat(row.amount),
                parseFloat(row.paidReceiveAmount),
                parseFloat(row.balance)
            ]);
        }

        return data;
    }
};

reportsData[REPORTS.PAYABLE_ACCOUNTS] = {
    headers: [
        { key: 'Fecha', width: 20 },
        { key: 'Cliente (agencia)', width: 30 },
        { key: 'Orden de servicio', width: 20 },
        { key: 'Reservaciones', width: 40 },
        { key: 'Estatus', width: 20 },
        { key: 'Total', width: 20, format: '$#,##0.00' },
        { key: 'Pagado', width: 20, format: '$#,##0.00' },
        { key: 'Saldo', width: 20, format: '$#,##0.00' }
    ],
    formatData(rows) {
        let data = [];

        for (let row of rows) {
            data.push([
                row.processed,
                row.commercialName,
                row.folio,
                row.businessOrderFolio,
                row.status,
                parseFloat(row.amount),
                parseFloat(row.paidReceiveAmount),
                parseFloat(row.balance)
            ]);
        }

        return data;
    }
};

export default reportsData;