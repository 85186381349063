var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validateForm(false)}}},[_c('font-awesome-icon',{attrs:{"icon":"print"}}),_vm._v(" Generar reporte")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"user-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.getReport)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reporte","invalid-feedback":"Selecciona el reporte","state":errors[0] ? false : null}},[_c('v-select',{attrs:{"options":_vm.reports,"clearable":true,"placeholder":"Reporte","reduce":function (report) { return report.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Sin resultados.")]},proxy:true}],null,true),model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha inicial","invalid-feedback":"Ingresa la fecha inicial","state":errors[0] ? false : null}},[_c('b-form-datepicker',_vm._b({attrs:{"boundary":"window","placeholder":"Fecha inicial","locale":"es","hide-header":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'b-form-datepicker',_vm.datePickerLabels,false))],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha final","invalid-feedback":"Ingresa la fecha final","state":errors[0] ? false : null}},[_c('b-form-datepicker',_vm._b({attrs:{"boundary":"window","placeholder":"Fecha final","locale":"es","hide-header":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'b-form-datepicker',_vm.datePickerLabels,false))],1)]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }